<template>
  <div class="main">
    <Line message="" theme="dark"></Line>

    <div class="container">
      <div class="title-col wow animate__animated animate__fadeInUp">
        Regenerative Care
      </div>
      <div class="text-col text-start wow animate__animated animate__fadeInUp">
        <span class="fw-bold">- Welcome to Regenerative Care</span>, your
        trusted partner in comprehensive wound care solutions. At Regenerative
        Care, we understand the challenges and complexities associated with
        wound management. That's why we are dedicated to offering advanced
        products and resources to support optimal healing outcomes.
      </div>

      <!-- Podiatrists Section -->
      <h2 class="title-col wow animate__animated animate__fadeInUp">
        Insurance Verification Request
      </h2>
      <div class="d-flex flex-column-reverse flex-md-row">
        <div
          class="col col-image mb-3 mb-md-0 wow animate__animated animate__fadeInUp"
        >
          <img src="@/assets/Home/RC/insurance.png" alt="About Image" />
        </div>
        <div
          class="col col-text-section wow animate__animated animate__fadeInUp"
        >
          <p class="text-col">
            Identify your patient or patients with a chronic wound. In order for
            our Insurance Verification Request to get approved, the patient
            needs medical notes proving treatment has been attempted to heal the
            chronic wound for over 30 days. After IVR is approved, we will ship
            you your Amniotic Tissue on net pay 60 terms.
          </p>
        </div>
      </div>

      <!-- Dermatologists Section -->
      <h2 class="title-col wow animate__animated animate__fadeInUp">Healing</h2>
      <div class="d-flex flex-column-reverse flex-md-row">
        <div
          class="col col-text-section wow animate__animated animate__fadeInUp"
        >
          <p class="text-col">
            Once you receive your Amniotic Tissue you're ready to apply it to
            the chronic wound. Take pictures before each application with the
            ruler included in your shipment. This will prove medical necessity
            as you reapply your Amniotic Tissue each week to show the wound is
            healing and that the proper size patch was used during treatment.
          </p>
        </div>
        <div
          class="col col-image mb-3 mb-md-0 wow animate__animated animate__fadeInUp"
        >
          <img src="@/assets/Home/RC/healing.png" alt="Doctor Image" />
        </div>
      </div>

      <!-- Surgeons Section -->
      <h2 class="title-col wow animate__animated animate__fadeInUp">
        Clawbacks
      </h2>
      <div class="d-flex flex-column-reverse flex-md-row">
        <div
          class="col col-image mb-3 mb-md-0 wow animate__animated animate__fadeInUp"
        >
          <img src="@/assets/Home/RC/clawbacks.png" alt="Baby Image" />
        </div>
        <div
          class="col col-text-section wow animate__animated animate__fadeInUp"
        >
          <p class="text-col">
            Our wound billing specialists will comb through your medical notes
            in your EMR to make sure the proper verbiage is used to avoid any
            future clawbacks. This is the #1 reason physicians and NP's receive
            clawbacks from Medicare by improperly documenting the patient's
            progress, current state, and diagnosis codes.
          </p>
        </div>
      </div>

      <!-- Enhanced "Get in Touch" Section -->
      <div class="row mt-5">
        <div class="wow animate__animated animate__fadeInUp">
          <h3 class="text-center">Ready to Learn More?</h3>
          <p class="text-center">
            Contact us today to explore how Regenerative Care can assist with
            your wound care needs.
          </p>
          <div class="text-center">
            <router-link
              to="/contacts"
              class="btn btn-get wow animate__animated animate__fadeInUp"
            >
              Get in Touch
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Line from "../Line.vue";
import { WOW } from "wowjs";
import "animate.css";

export default {
  name: "RegenerativeCare",
  components: {
    Line,
  },
  mounted() {
    new WOW().init();
  },
};
</script>

<style lang="scss" scoped>
.main {
  background-color: #fff;
  color: #000;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.title-col {
  font-weight: 100;
  font-size: 6.5vh; /* Responsive font size */
  letter-spacing: -0.05em;
  line-height: 1.1;
  margin-top: 5vw; /* Responsive margin */
  margin-bottom: 3vw; /* Responsive margin */

  &:first-of-type {
    margin-top: 3vw; /* Responsive margin */
  }
}

.text-col {
  text-align: center;
  letter-spacing: 0em;
  line-height: 1.4;
  font-size: 18px;
  width: 90%;
  margin: auto; /* Center align on smaller screens */

  @media (max-width: 768px) {
    font-size: 16px; /* Adjust font size for smaller screens */
  }
}

.col-text-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-image {
  img {
    border-radius: 15px;
    max-width: 100%;
    height: auto;
  }
}

@media (min-width: 768px) {
  .d-flex.flex-column-reverse.flex-md-row {
    flex-direction: row-reverse;
  }

  .title-col {
    font-size: 3rem;
    margin-top: 100px;
    margin-bottom: 30px;
  }
}

.btn-get {
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 12px 40px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #333;
    color: #fff;
  }
}
</style>
