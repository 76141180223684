<template>
  <div class="container">
    <div
      class="text-col w-100 text-start wow animate__animated animate__fadeInUp animate__fast"
    >
      <h2
        class="title-col wow animate__animated animate__fadeInUp animate__fast"
      >
        About Regenerative Care
      </h2>
      <p>
        Welcome to Regenerative Care, where we're dedicated to revolutionizing
        healthcare through the latest advancements in allograft tissue. At
        Regenerative Care, our mission is simple yet profound: to enhance
        surgical outcomes and improve patient quality of life while honoring the
        invaluable gift of donated human tissue.
      </p>

      <h2
        class="title-col wow animate__animated animate__fadeInUp animate__fast"
      >
        Our Commitment
      </h2>
      <p>
        We're committed to pushing the boundaries of medical innovation to
        provide cutting-edge solutions that make a meaningful difference in the
        lives of patients and healthcare professionals alike. With a steadfast
        dedication to excellence, integrity, and compassion, we strive to set
        new standards of care and pave the way for a healthier, more vibrant
        future.
      </p>

      <h2
        class="title-col wow animate__animated animate__fadeInUp animate__fast"
      >
        Celebrating Impact
      </h2>
      <p>
        Every day, we celebrate the positive impact our services have on the
        lives of countless patients. From enhancing wound healing to supporting
        ocular health, our allograft tissue products play a crucial role in
        restoring health, vitality, and hope to individuals across the globe.
        With each success story, our list of accomplishments and testimonials
        grows, fueling our passion to continue making a difference in the world
        of healthcare.
      </p>

      <h2
        class="title-col wow animate__animated animate__fadeInUp animate__fast"
      >
        Our Vision
      </h2>
      <p>
        Our vision extends beyond the present moment, as we aspire to shape the
        future of healthcare through ongoing innovation, collaboration, and
        dedication to excellence. By staying at the forefront of medical
        advancements and embracing the latest technologies, we're poised to lead
        the way in delivering transformative solutions that redefine the
        possibilities of patient care.
      </p>

      <h2
        class="title-col wow animate__animated animate__fadeInUp animate__fast"
      >
        Join Us on Our Journey
      </h2>
      <p>
        We invite you to join us on our journey as we continue to push the
        boundaries of medical possibility and make a lasting impact on the world
        of healthcare. Whether you're a patient, healthcare professional, or
        partner organization, together, we can create a future where every
        individual has access to the care they need to live their healthiest,
        happiest life. Thank you for getting to know us. We look forward to
        serving you and making a positive difference in your healthcare journey.
      </p>
    </div>

    <div>
      <div class="d-flex flex-column-reverse flex-md-row">
        <div
          class="col col-image mb-3 mb-md-0 wow animate__animated animate__fadeInUp animate__fast"
        >
          <img src="@/assets/Home/About/Holding.webp" alt="About Image" />
        </div>
        <div
          class="col col-text-section wow animate__animated animate__fadeInUp animate__fast"
        >
          <p class="text-col">
            Our mission is to provide physicians with the best solutions for
            their patients. We collaborate closely with facilities and
            distributor partners to simplify the ordering process, ensure
            product supply, arrange convenient and timely delivery, maintain
            inventory levels, and streamline invoicing. Regenerative Care offers
            nearly every allograft processing and preservation technique
            available today to meet the unique needs of physicians and patients.
          </p>
        </div>
      </div>
    </div>

    <div>
      <h2
        class="title-col wow animate__animated animate__fadeInUp animate__fast"
      >
        Customer Success
      </h2>
      <div class="d-flex flex-column-reverse flex-md-row">
        <div
          class="col col-text-section wow animate__animated animate__fadeInUp animate__fast"
        >
          <p class="text-col">
            Historically, we fulfill over 98% of orders placed, responding to
            requests in five minutes or less. Through our efforts to support
            physicians and patients, we have developed a clinically minded
            Customer Success support team. The Regenerative Care team
            understands your specific case needs and is dedicated to ensuring
            you get the correct graft, on time, every time. We pride ourselves
            on supporting our clients with a comprehensive 360-degree approach
            through each phase of the allograft order and distribution process.
          </p>
        </div>
        <div
          class="col col-image mb-3 mb-md-0 wow animate__animated animate__fadeInUp animate__fast"
        >
          <img src="@/assets/Home/About/Doctor.webp" alt="Doctor Image" />
        </div>
      </div>
    </div>

    <div>
      <h2
        class="title-col wow animate__animated animate__fadeInUp animate__fast"
      >
        Donor Screening
      </h2>
      <div class="d-flex flex-column-reverse flex-md-row">
        <div
          class="col col-image mb-3 mb-md-0 wow animate__animated animate__fadeInUp animate__fast"
        >
          <img src="@/assets/Home/About/Baby.webp" alt="Baby Image" />
        </div>
        <div
          class="col col-text-section wow animate__animated animate__fadeInUp animate__fast"
        >
          <p class="text-col">
            All donors are recovered in the United States and meet FDA, AATB,
            and applicable state requirements. Potential donors are screened for
            risk factors associated with infectious diseases and medical
            conditions that might disqualify them from donation. Historically,
            fewer than seven percent of referred donors pass the screening
            process and are accepted as eligible donors.
          </p>
        </div>
      </div>
    </div>

    <div
      class="title-col wow animate__animated animate__fadeInUp animate__fast"
    >
      What We Do
    </div>
    <div
      class="text-col w-100 text-start wow animate__animated animate__fadeInUp animate__fast"
    >
      At Regenerative Care, we supply physicians with amniotic membrane
      allografts for the treatment of chronic wounds. Our grafts are
      FDA-registered, safe, chorion-free, and tested for communicable diseases.
      We ensure ethical procurement and provide back office support to guarantee
      consistent reimbursement. For more information, visit our FAQ page.
    </div>

    <div
      class="title-col wow animate__animated animate__fadeInUp animate__fast"
    >
      How Do We Compare To Other Tissue Banks?
    </div>
    <div
      class="text-col w-100 text-start wow animate__animated animate__fadeInUp animate__fast"
    >
      At Regenerative Care, we are proud to be trusted by medical professionals
      across the country. Here’s how we stand out:
    </div>

    <div
      class="table-responsive wow animate__animated animate__fadeInUp animate__fast"
    >
      <table class="table table-bordered table-dark mt-5 mb-5 rounded">
        <thead>
          <tr>
            <th></th>
            <th>Regenerative Care</th>
            <th>Other Tissue Banks</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Dual Layer Amnion</td>
            <td><i class="bi bi-check text-success"></i></td>
            <td><i class="bi bi-check text-success"></i></td>
          </tr>
          <tr>
            <td>Naturally Adhering</td>
            <td><i class="bi bi-check text-success"></i></td>
            <td><i class="bi bi-check text-success"></i></td>
          </tr>
          <tr>
            <td>Multiple Sizes</td>
            <td><i class="bi bi-check text-success"></i></td>
            <td><i class="bi bi-check text-success"></i></td>
          </tr>
          <tr>
            <td>Net Pay 30</td>
            <td><i class="bi bi-check text-success"></i></td>
            <td><i class="bi bi-check text-success"></i></td>
          </tr>
          <tr>
            <td>Staff, Physician, & Clinical Support</td>
            <td><i class="bi bi-check text-success"></i></td>
            <td><i class="bi bi-x text-danger"></i></td>
          </tr>
          <tr>
            <td>Billing Support</td>
            <td><i class="bi bi-check text-success"></i></td>
            <td><i class="bi bi-x text-danger"></i></td>
          </tr>
          <tr>
            <td>Reliable Reimbursement</td>
            <td><i class="bi bi-check text-success"></i></td>
            <td><i class="bi bi-x text-danger"></i></td>
          </tr>
          <tr>
            <td>Protection From Clawbacks</td>
            <td><i class="bi bi-check text-success"></i></td>
            <td><i class="bi bi-x text-danger"></i></td>
          </tr>
          <tr>
            <td>24/7 Order Support</td>
            <td><i class="bi bi-check text-success"></i></td>
            <td><i class="bi bi-x text-danger"></i></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import "animate.css";

export default {
  name: "AboutSection",
  mounted() {
    new WOW().init();
  },
};
</script>

<style lang="scss" scoped>
.title-col {
  font-weight: 100;
  font-size: 6.5vh; /* Responsive font size */
  color: #fff;
  letter-spacing: -0.05em;
  line-height: 1.1;
  margin-top: 5vw; /* Responsive margin */
  margin-bottom: 3vw; /* Responsive margin */

  &:first-of-type {
    margin-top: 3vw; /* Responsive margin */
  }
}

.text-col {
  color: #fff;
  text-align: center;
  letter-spacing: 0em;
  line-height: 1.4;
  font-size: 18px;
  text-align: center;
  width: 90%;
  @media (max-width: 992px) {
    font-size: 100%;
  }
}

.col-text-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-image {
  img {
    border-radius: 15px;
    max-width: 100%;
    height: auto;
  }
}

@media (min-width: 768px) {
  .d-flex.flex-column-reverse.flex-md-row {
    flex-direction: row-reverse;
  }

  .title-col {
    font-size: 3rem;
    margin-top: 100px;
    margin-bottom: 30px;
  }
}

.animate__fast {
  animation-duration: 0.5s !important;
}
</style>
